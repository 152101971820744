<template>
    <v-container fluid>
        <v-form ref="testReceiptForm" @submit.prevent="saveTestReceipt" class="custom-form">
        <v-row no-gutters>
            <v-col cols="12">
                <v-card :elevation="1" color="grey lighten-3">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-toolbar
                                color="#546E7A"
                                :elevation="1"
                                height="30px"
                                dark
                            >
                                <v-toolbar-title class="subtitle-2"
                                    >Test Receipt</v-toolbar-title
                                >
                            </v-toolbar>
                            <v-card-text class="py-0 mt-1">
                                <v-row dense>
                                    <v-col md="4" cols="4">
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3">Patient</v-col>
                                            <v-col cols="9">
                                                <v-combobox
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @focus="$store.dispatch('patient/getPatients')"
                                                    :loading="$store.getters['patient/loading']"
                                                    :items="[{id: null, display_text: 'New Patient'}].concat($store.getters['patient/patients'])"
                                                    item-value="id"
                                                    item-text="display_text"
                                                    v-model="selectedPatient"
                                                    :rules="[v => !!v]"
                                                >
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3">Name</v-col>
                                            <v-col cols="9">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    hide-details
                                                    :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                    v-model="newPatient.name"
                                                    :rules="[v => !!v]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mb-1" v-if="selectedPatient != null && selectedPatient.display_text == 'New Patient'">
                                            <v-col cols="3">Age</v-col>
                                            <v-col cols="9">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    hide-details
                                                    :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                    v-model="newPatient.age"
                                                    :rules="[v => !!v]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="4" cols="4">
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3" class="text-right pr-2">Reffered by</v-col>
                                            <v-col cols="9">
                                                <v-combobox
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @focus="$store.dispatch('doctor/getDoctors')"
                                                    :loading="$store.getters['doctor/loading']"
                                                    :items="[{id: null, display_text: 'N/A'}].concat($store.getters['doctor/doctors'])"
                                                    item-text="display_text"
                                                    item-value="id"
                                                    v-model="selectedRefferedBy"
                                                    :rules="[v => !!v]"
                                                >
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3" class="text-right pr-2">Mobile</v-col>
                                            <v-col cols="9">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    hide-details
                                                    :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                    v-model="newPatient.mobile"
                                                    :rules="[v => !!v]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="selectedPatient != null && selectedPatient.display_text == 'New Patient'">
                                            <v-col cols="3" class="text-right pr-2">Gender</v-col>
                                            <v-col cols="9">
                                                <v-radio-group row v-model="newPatient.gender" no-gutters style="margin:0px;padding:0px">
                                                    <v-radio
                                                        label="Male"
                                                        value="Male"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Female"
                                                        value="Female"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="4" cols="4">
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3" class="text-right pr-2">Bill Date</v-col>
                                            <v-col cols="9">
                                                <v-menu
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="0"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            hide-details
                                                            v-model="billDate"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            autocomplete="off"
                                                        >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="billDate"
                                                        @input="menu = false"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="mb-1">
                                            <v-col cols="3" class="text-right pr-2">Address</v-col>
                                            <v-col cols="9">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    hide-details
                                                    :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                    v-model="newPatient.address"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters class="">
                                            <v-col cols="3" class="text-right pr-2">RF By</v-col>
                                            <v-col cols="8">
                                               <v-combobox
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @focus="$store.dispatch('pharmacist/getPharmacists')"
                                                    :loading="$store.getters['pharmacist/loading']"
                                                    :items="$store.getters['pharmacist/pharmacists']"
                                                    item-value="id"
                                                    item-text="display_text"
                                                    v-model="selectedPharmacistBy"
                                                >
                                                </v-combobox>
                                            </v-col>
                                            <v-col cols="1">
                                                <pharma-dialog :icon="true" ref="pharmaDialog"></pharma-dialog>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card :elevation="1" color="grey lighten-3" class="mt-2">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-card-text class="py-0 mt-1">
                                <v-form ref="testForm" @submit.prevent="addToCart">
                                    <v-row dense>
                                        <v-col cols="3">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Test Name</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('test/getTests')"
                                                        :loading="$store.getters['test/loading']"
                                                        :items="filterTests"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="selectedTest"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Room</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="test.room_number"
                                                        readonly="readonly"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Price</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="test.price"
                                                        readonly="readonly"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Delivery Date</v-col>
                                                <v-col cols="4" class="pr-1">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="deliveryDate"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker 
                                                            v-model="deliveryDate"
                                                            :rules="[v => !!v]"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="deliveryTime"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            </v-text-field>
                                                        </template>
                                                        <v-time-picker 
                                                            v-model="deliveryTime"
                                                            :rules="[v => !!v]"
                                                            format="12hr"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col md="1" cols="1">
                                            <v-btn type="submit" height="26px" dark block color="deep-orange">Add</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="9" class="pb-0">
                <v-simple-table dense>
                    <template>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Test Name</th>
                                <th>Room No.</th>
                                <th>Price</th>
                                <th>Delivery Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(_test, i) of cartTestList" :key="i">
                                <td>{{ i + 1 }}</td>
                                <td>{{ _test.name }}</td>
                                <td>{{ _test.room_number }}</td>
                                <td>{{ _test.price }}</td>
                                <td>{{ _test.deliveryDateTime }}</td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                @click="removeTest(i)"
                                                color="error"
                                                v-on="on"
                                                >mdi-delete-circle-outline</v-icon
                                            >
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-textarea
                    class="mt-3"
                    outlined
                    name="input-7-4"
                    label="Note"
                    rows="3"
                    style="max-width:400px"
                    v-model="note"
                ></v-textarea>
            </v-col>
            <v-col cols="3" class="pb-0">
                <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col md="12" cols="12">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Sub Total</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        v-model.number="bill.subTotal"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">VAT</v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        autocomplete="off"
                                                        v-model.number="bill.vatPercent"
                                                        @input="calculateTotal"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1" class="pl-1">%</v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        autocomplete="off"
                                                        readonly
                                                        v-model.number="bill.vatAmount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Discount</v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bill.discountPercent"
                                                        @input="calculateTotal"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1" class="pl-1">%</v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bill.discountAmount"
                                                        id="discount__amount"
                                                        @input="calculateTotal"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Others</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bill.others"
                                                        @input="calculateTotal"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Total Bill</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        v-model.number="bill.totalAmount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Paid</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bill.paid"
                                                        @input="calcutatedDueAmount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Due</v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bill.due"
                                                        class="pr-1"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['patient/patientPreviousDue']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                            <v-col cols="6">
                                                            <v-btn @click="resetTestReceiptForm" height="28px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="28px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
            </v-col>
        </v-row>
        </v-form>
    </v-container>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'
import pharmaDialog from '../../components/Pharmacist.vue'

export default {
    name: "TestReceipt",

    components: {
		"pharma-dialog": pharmaDialog,
    },

    data: () => ({
        menu: false,
        selectedPatient: null,
        newPatient: {
            name: null,
            mobile: null,
            address: null,
            age: null,
            gender: null,
        },

        note: '',
        selectedRefferedBy: null,
        selectedPharmacistBy: null,
        billDate: new Date().toISOString().substr(0, 10),

        bill: {
            subTotal: 0,
            vatPercent: 0,
            vatAmount: 0,
            discountPercent: 0,
            discountAmount: 0,
            others: 0,
            totalAmount: 0,
            paid: 0,
            due: 0,
        },

        cartTestList: [],
        selectedTest: null,
        deliveryDate: null,
        deliveryTime: null,
        test: {
            id: null,
            room_number: null,
            name: null,
            price: null,
        },

        waitingForSave: false,
        editMode: false,
    }),
    watch: {
        selectedPatient: function (val) {
            if (val == null) return
            if (val.display_text == 'New Patient') Object.keys(this.newPatient).map(k => this.newPatient[k] = null);
            else Object.keys(this.newPatient).map(k => this.newPatient[k] = val[k]);
            this.$store.dispatch('patient/getPatientPreviousDue', {patient_id: val.id})
        },
        selectedTest: function (val) {
            if (val == null) return
            this.test.id = val.id
            this.test.name = val.name
            this.test.room_number = val.room_number
            this.test.price = val.price
            let convertedDateTime = this.convertTestTime(val.days_needed, val.hours_needed, val.minutes_needed)
            this.deliveryDate = convertedDateTime.date
            this.deliveryTime = convertedDateTime.time
        },
        'bill.vatPercent': function (val) {
            if (val > 100) this.bill.vatPercent = this.bill.vatAmount = 0
        },
        'bill.discountPercent': function (val) {
            if (val > 100) this.bill.discountPercent = this.bill.discountAmount = 0
        },
        'bill.subTotal': function (val) {
            if (val == 0) {
                this.bill.vatPercent = 0
                this.bill.discountPercent = 0
                this.bill.others = 0
                this.bill.paid = 0
            }
        },
    },
    computed: {
        filterTests() {
            let idS = this.cartTestList.map(item => item.id)
            return this.$store.getters['test/tests'].filter(item => idS.indexOf(item.id) == -1)
        },
    },
    created() {
        if (this.$route.params.id != undefined) this.editTestReceipt(this.$route.params.id)
    },
    methods: {
        calculateTotal() {
            this.bill.vatAmount = ((this.bill.subTotal * this.bill.vatPercent) / 100).toFixed(2)

            if (event.target.id == 'discount__amount') {
                this.bill.discountPercent = ((this.bill.discountAmount / this.bill.subTotal) * 100).toFixed(2)
            } else {
                this.bill.discountAmount = ((this.bill.subTotal * this.bill.discountPercent) / 100).toFixed(2)
            }

            let totalAmount = parseFloat(this.bill.subTotal)
            this.bill.paid = this.bill.subTotal;
            totalAmount += parseFloat(this.bill.vatAmount)
            totalAmount -= parseFloat(this.bill.discountAmount)
            totalAmount += parseFloat(this.bill.others)

            this.bill.totalAmount = totalAmount

        },
        calcutatedDueAmount() {
            this.bill.due = this.bill.totalAmount - this.bill.paid
        },
        calculateSubTotal() {
            let total = 0
            this.cartTestList.forEach(item => total += Number(item.price))
            this.bill.subTotal = total.toFixed(2)
        },
        addToCart() {
            if (!this.$refs.testForm.validate()) return
            this.cartTestList.push({
                ...this.test,
                deliveryDateTime: `${this.deliveryDate} ${this.deliveryTime}`
            })
            this.calculateSubTotal()
            this.calculateTotal()
            this.resetTestForm()
        },
        resetTestForm() {
            this.selectedTest = null
            this.deliveryDate = this.deliveryTime = null
            Object.keys(this.test).map(k => this.test[k] = null)
            this.$refs.testForm.resetValidation()
        },
        removeTest(index) {
            this.cartTestList.splice(index, 1)
            this.calculateSubTotal()
            this.calculateTotal()
        },
        convertTestTime(day, hour, minute) {
            return {
                date: moment().add(day, 'days').add(hour, 'hours').add(minute, 'minutes').format('YYYY-MM-DD'),
                time: moment().add(day, 'days').add(hour, 'hours').add(minute, 'minutes').format('hh:mm'),
            }
        },

        async saveTestReceipt() {
            if (!this.$refs.testReceiptForm.validate()) return
            
            if (!this.cartTestList.length) {
                swal({title: 'No test item selected', icon: 'warning'})
                return
            }

            this.waitingForSave = true

            let testReceipt = {
                reffered_by: this.selectedRefferedBy.id,
                bill_date: this.billDate,
                sub_total_amount: this.bill.subTotal,
                vat_amount: this.bill.vatAmount,
                discount_amount: this.bill.discountAmount,
                others_amount: this.bill.others,
                total_amount: this.bill.totalAmount,
                paid_amount: this.bill.paid,
                due_amount: this.bill.due,
                note: this.note,
                testReceiptDetails: this.cartTestList,
                ref_by: this.selectedPharmacistBy == null ? null :this.selectedPharmacistBy.id
            }


            if (this.selectedPatient.display_text == 'New Patient') testReceipt.patient = this.newPatient
            else testReceipt.patient_id = this.selectedPatient.id

            if (this.$route.params.id != undefined && this.editMode) {
                let res = await this.$store.dispatch('testReceipt/saveTestReceipt', {
                    url: 'update-test-receipt',
                    data: {...testReceipt, id: this.$route.params.id}
                })
                
                if (res.success) this.$router.go(-1)

            } else {
                let res = await this.$store.dispatch('testReceipt/saveTestReceipt', {
                    url: 'add-test-receipt',
                    data: testReceipt
                })

                if (res.success) {
                    this.resetTestReceiptForm()
                    swal({
                        title: 'Do you want to show slip?',
                        icon: 'success',
                        buttons: true
                    }).then(response => {
                        if (response) this.$router.push(`/account/test-receipt-slip/${res.testReceiptId}`)
                    })
                }
            }

            this.waitingForSave = false
       },

       async editTestReceipt(testReceiptId) {
            if (testReceiptId == null) return
            await this.$store.dispatch('testReceipt/getTestReceipt', {id: testReceiptId})
            let testReceipt = this.$store.getters['testReceipt/testReceipt']
            if (testReceipt == null) return
            this.selectedPatient = testReceipt.patient
            this.selectedPharmacistBy = testReceipt.pharmacist
            if(testReceipt.pharmacist != null) {
                this.selectedPharmacistBy.display_text = (testReceipt.pharmacist == undefined) ? '' : `${testReceipt.pharmacist.code} - ${testReceipt.pharmacist.name} - ${testReceipt.pharmacist.phone}`
            }
            this.selectedRefferedBy = (testReceipt.doctor != null ? testReceipt.doctor : {id: null, display_text: 'N/A'})
            this.billDate = testReceipt.bill_date
            this.bill.subTotal = testReceipt.sub_total_amount
            this.bill.vatPercent = ((testReceipt.vat_amount / testReceipt.sub_total_amount) * 100).toFixed(2)
            this.bill.vatAmount = testReceipt.vat_amount
            this.bill.discountPercent = ((testReceipt.discount_amount / this.bill.subTotal) * 100).toFixed(2)
            this.bill.discountAmount = testReceipt.discount_amount
            this.bill.others = testReceipt.others_amount
            this.bill.totalAmount = testReceipt.total_amount
            this.bill.paid = testReceipt.paid_amount
            this.bill.due = testReceipt.due_amount
            this.note = testReceipt.note
            this.editMode = true
            let testDetails = testReceipt.test_receipt_details != null ? testReceipt.test_receipt_details : []
            testDetails.forEach(item => {
                let convertedDateTime = this.convertTestTime(item.test.days_needed, item.test.hours_needed, item.test.minutes_needed)
                this.cartTestList.push({
                    id: item.test_id, 
                    name: item.test.name, 
                    price: item.test.price,
                    room_number: item.test.room_number,
                    deliveryDateTime: `${convertedDateTime.date} ${convertedDateTime.time}`,
                })
           })
       },

       resetTestReceiptForm() {
           this.note = ''
           this.selectedPatient = null
           this.selectedRefferedBy = null
           this.selectedPharmacistBy = null
           this.billDate = new Date().toISOString().slice(0, 10)
           this.cartTestList = []
           Object.keys(this.newPatient).map(k => this.newPatient[k] = '')
           Object.keys(this.bill).map(k => this.bill[k] = 0)
           this.$store.commit('patient/changePatientPreviousDue', 0)
           this.editMode = false
           this.$refs.testReceiptForm.resetValidation()
       }
    }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
    font-size: 18px !important;
    top: -3px !important;
}
.v-icon.v-icon[data-v-1f38b4e5] {
    font-size: 14px !important;
    top: 0px !important;
}
.caption {
    line-height: 1 !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    font-size: 12px;
    padding: 0px 1px;
    background: #607D8B;
    color: #ffffff;
    border: 1px solid #ccc;
    text-align: center;
    height: 24px !important;
    letter-spacing: .7px;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
    height: 20px !important;
    padding: 1px 5px !important;
    font-size: 12px !important;
    border: 1px solid #ccc !important;
    text-align: center;
}
.v-btn:not(.v-btn--round).v-size--default {
    padding: 0px 7px;
}
</style>